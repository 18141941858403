<template>
  <div class="reset-password-dialog">
    <h2
      class="text-center text-body-1 font-weight-light reset-password-dialog__subtitle"
    >
      {{ $trans("reset_password_text") }}
    </h2>
    <reset-password-dialog-form class="reset-password-dialog__form" />
  </div>
</template>

<script>
export default {
  name: "ResetPasswordDialog",
  components: {
    ResetPasswordDialogForm: () => import("./ResetPasswordDialogForm"),
  },
};
</script>

<style lang="scss" socped>
.reset-password-dialog__subtitle {
  padding: 0 10px;
}
.reset-password-dialog__form {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .reset-password-dialog__subtitle {
    margin-bottom: 10px;
    padding: 0 65px;
  }

  .reset-password-dialog__form {
    padding: 0 65px;
  }
}
</style>
